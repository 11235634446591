var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Directions"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeDropdownDirection) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('span', [_vm._v("To have the dropdown aligned on the right, set the right")]), _c('code', [_vm._v("prop")]), _vm._v(". "), _c('span', [_vm._v("Turn your dropdown menu into a drop-up menu by setting the")]), _c('code', [_vm._v("dropup")]), _c('span', [_vm._v("prop.")]), _c('code', [_vm._v("dropright")]), _c('span', [_vm._v("takes precedence over")]), _c('code', [_vm._v("dropleft")]), _vm._v(". "), _c('span', [_vm._v("Neither")]), _c('code', [_vm._v("dropright")]), _c('span', [_vm._v("or")]), _c('code', [_vm._v("dropleft")]), _c('span', [_vm._v("have any effect if")]), _c('code', [_vm._v("dropup")]), _c('span', [_vm._v("is set.")])]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "id": "dropdown-left",
      "left": "",
      "text": "Left align",
      "variant": "primary"
    }
  }, [_c('b-dropdown-item', [_vm._v(" Action ")]), _c('b-dropdown-item', [_vm._v(" Another action ")]), _c('b-dropdown-item', [_vm._v(" Something else here ")])], 1), _c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "id": "dropdown-right",
      "right": "",
      "text": "Right align",
      "variant": "primary"
    }
  }, [_c('b-dropdown-item', [_vm._v(" Action ")]), _c('b-dropdown-item', [_vm._v(" Another action ")]), _c('b-dropdown-item', [_vm._v(" Something else here ")])], 1), _c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "id": "dropdown-dropup",
      "dropup": "",
      "right": "",
      "text": "Drop-Up",
      "variant": "primary"
    }
  }, [_c('b-dropdown-item', [_vm._v(" Action ")]), _c('b-dropdown-item', [_vm._v(" Another action ")]), _c('b-dropdown-item', [_vm._v(" Something else here ")])], 1), _c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "id": "dropdown-dropright",
      "dropright": "",
      "text": "Drop-Right",
      "variant": "primary"
    }
  }, [_c('b-dropdown-item', [_vm._v(" Action ")]), _c('b-dropdown-item', [_vm._v(" Another action ")]), _c('b-dropdown-item', [_vm._v(" Something else here ")])], 1), _c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "id": "dropdown-dropleft",
      "dropleft": "",
      "text": "Drop-Left",
      "variant": "primary"
    }
  }, [_c('b-dropdown-item', [_vm._v(" Action ")]), _c('b-dropdown-item', [_vm._v(" Another action ")]), _c('b-dropdown-item', [_vm._v(" Something else here ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
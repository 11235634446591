var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Sizes"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeDropdownSize) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('span', [_vm._v("Dropdowns work with trigger buttons of all sizes, including default and split dropdown buttons. Set the size prop to either ")]), _c('code', [_vm._v("sm")]), _c('span', [_vm._v(" for small button(s), or ")]), _c('code', [_vm._v("lg")]), _c('span', [_vm._v(" for large button(s).")])]), _c('div', {
    staticClass: "d-flex flex-wrap justify-content-between"
  }, [_c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "size": "lg",
      "text": "Large",
      "variant": "primary"
    }
  }, [_c('b-dropdown-item', [_vm._v("Option 1")]), _c('b-dropdown-item', [_vm._v("Option 2")]), _c('b-dropdown-item', [_vm._v("Option 3")])], 1), _c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "text": "Default",
      "variant": "primary"
    }
  }, [_c('b-dropdown-item', [_vm._v("Option 1")]), _c('b-dropdown-item', [_vm._v("Option 2")]), _c('b-dropdown-item', [_vm._v("Option 3")])], 1), _c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "size": "sm",
      "text": "Small",
      "variant": "primary"
    }
  }, [_c('b-dropdown-item', [_vm._v("Option 1")]), _c('b-dropdown-item', [_vm._v("Option 2")]), _c('b-dropdown-item', [_vm._v("Option 3")])], 1)], 1), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "split": "",
      "right": "",
      "size": "lg",
      "text": "Large",
      "variant": "primary"
    }
  }, [_c('b-dropdown-item', [_vm._v("Option 1")]), _c('b-dropdown-item', [_vm._v("Option 2")]), _c('b-dropdown-item', [_vm._v("Option 3")])], 1), _c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "split": "",
      "right": "",
      "text": "Default",
      "variant": "primary"
    }
  }, [_c('b-dropdown-item', [_vm._v("Option 1")]), _c('b-dropdown-item', [_vm._v("Option 2")]), _c('b-dropdown-item', [_vm._v("Option 3")])], 1), _c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "split": "",
      "right": "",
      "size": "sm",
      "text": "Small",
      "variant": "primary"
    }
  }, [_c('b-dropdown-item', [_vm._v("Option 1")]), _c('b-dropdown-item', [_vm._v("Option 2")]), _c('b-dropdown-item', [_vm._v("Option 3")])], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
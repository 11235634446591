var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Gradient"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeGradient) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('span', [_vm._v("To create a gradient dropdown use ")]), _c('code', [_vm._v("variant=\"gradient-{color}\"")]), _c('span', [_vm._v(" prop with your ")]), _c('code', [_vm._v("<b-dropdown>")])]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-dropdown', {
    attrs: {
      "text": "Primary",
      "variant": "gradient-primary"
    }
  }, [_c('b-dropdown-item', [_vm._v(" Option 1 ")]), _c('b-dropdown-item', [_vm._v(" Option 2 ")]), _c('b-dropdown-item', [_vm._v(" Option 3 ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', [_vm._v("Separated link")])], 1), _c('b-dropdown', {
    attrs: {
      "text": "Secondary",
      "variant": "gradient-secondary"
    }
  }, [_c('b-dropdown-item', [_vm._v(" Option 1 ")]), _c('b-dropdown-item', [_vm._v(" Option 2 ")]), _c('b-dropdown-item', [_vm._v(" Option 3 ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', [_vm._v("Separated link")])], 1), _c('b-dropdown', {
    attrs: {
      "text": "Success",
      "variant": "gradient-success"
    }
  }, [_c('b-dropdown-item', [_vm._v(" Option 1 ")]), _c('b-dropdown-item', [_vm._v(" Option 2 ")]), _c('b-dropdown-item', [_vm._v(" Option 3 ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', [_vm._v("Separated link")])], 1), _c('b-dropdown', {
    attrs: {
      "text": "Danger",
      "variant": "gradient-danger"
    }
  }, [_c('b-dropdown-item', [_vm._v(" Option 1 ")]), _c('b-dropdown-item', [_vm._v(" Option 2 ")]), _c('b-dropdown-item', [_vm._v(" Option 3 ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', [_vm._v("Separated link")])], 1), _c('b-dropdown', {
    attrs: {
      "text": "Warning",
      "variant": "gradient-warning"
    }
  }, [_c('b-dropdown-item', [_vm._v(" Option 1 ")]), _c('b-dropdown-item', [_vm._v(" Option 2 ")]), _c('b-dropdown-item', [_vm._v(" Option 3 ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', [_vm._v("Separated link")])], 1), _c('b-dropdown', {
    attrs: {
      "text": "Info",
      "variant": "gradient-info"
    }
  }, [_c('b-dropdown-item', [_vm._v(" Option 1 ")]), _c('b-dropdown-item', [_vm._v(" Option 2 ")]), _c('b-dropdown-item', [_vm._v(" Option 3 ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', [_vm._v("Separated link")])], 1), _c('b-dropdown', {
    attrs: {
      "text": "Dark",
      "variant": "gradient-dark"
    }
  }, [_c('b-dropdown-item', [_vm._v(" Option 1 ")]), _c('b-dropdown-item', [_vm._v(" Option 2 ")]), _c('b-dropdown-item', [_vm._v(" Option 3 ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', [_vm._v("Separated link")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Flat"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeFlat) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('span', [_vm._v("To create a flat dropdown use ")]), _c('code', [_vm._v("variant=\"flat-{color}\"")]), _c('span', [_vm._v(" with your ")]), _c('code', [_vm._v("<b-dropdown>")])]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "text": "Primary",
      "variant": "flat-primary"
    }
  }, [_c('b-dropdown-item', [_vm._v(" Option 1 ")]), _c('b-dropdown-item', [_vm._v(" Option 2 ")]), _c('b-dropdown-item', [_vm._v(" Option 3 ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', [_vm._v("Separated link")])], 1), _c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "text": "Secondary",
      "variant": "flat-secondary"
    }
  }, [_c('b-dropdown-item', [_vm._v(" Option 1 ")]), _c('b-dropdown-item', [_vm._v(" Option 2 ")]), _c('b-dropdown-item', [_vm._v(" Option 3 ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', [_vm._v("Separated link")])], 1), _c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(40, 199, 111, 0.15)',
      expression: "'rgba(40, 199, 111, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "text": "Success",
      "variant": "flat-success"
    }
  }, [_c('b-dropdown-item', [_vm._v(" Option 1 ")]), _c('b-dropdown-item', [_vm._v(" Option 2 ")]), _c('b-dropdown-item', [_vm._v(" Option 3 ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', [_vm._v("Separated link")])], 1), _c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(234, 84, 85, 0.15)',
      expression: "'rgba(234, 84, 85, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "text": "Danger",
      "variant": "flat-danger"
    }
  }, [_c('b-dropdown-item', [_vm._v(" Option 1 ")]), _c('b-dropdown-item', [_vm._v(" Option 2 ")]), _c('b-dropdown-item', [_vm._v(" Option 3 ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', [_vm._v("Separated link")])], 1), _c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 159, 67, 0.15)',
      expression: "'rgba(255, 159, 67, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "text": "Warning",
      "variant": "flat-warning"
    }
  }, [_c('b-dropdown-item', [_vm._v(" Option 1 ")]), _c('b-dropdown-item', [_vm._v(" Option 2 ")]), _c('b-dropdown-item', [_vm._v(" Option 3 ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', [_vm._v("Separated link")])], 1), _c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(0, 207, 232, 0.15)',
      expression: "'rgba(0, 207, 232, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "text": "Info",
      "variant": "flat-info"
    }
  }, [_c('b-dropdown-item', [_vm._v(" Option 1 ")]), _c('b-dropdown-item', [_vm._v(" Option 2 ")]), _c('b-dropdown-item', [_vm._v(" Option 3 ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', [_vm._v("Separated link")])], 1), _c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(30, 30, 30, 0.15)',
      expression: "'rgba(30, 30, 30, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "text": "Dark",
      "variant": "flat-dark"
    }
  }, [_c('b-dropdown-item', [_vm._v(" Option 1 ")]), _c('b-dropdown-item', [_vm._v(" Option 2 ")]), _c('b-dropdown-item', [_vm._v(" Option 3 ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', [_vm._v("Separated link")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }